/*@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');*/
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon-back {
  position: absolute;
  box-sizing: content-box;
  inset: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: auto;
  z-index: 2;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background-color: rgb(255, 255, 255);
  animation: 2s ease-in 0s infinite normal none running KDVxc;
}

@keyframes KDVxc {
  0% {
    transform: scale(0.8);
  }

  30% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

.icon {
  position: absolute;
  box-sizing: content-box;
  inset: 0;
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: auto;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    filter: brightness(1.1);
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100px;
    height: 100px;
    border-radius: 50% 0 50% 50%;
    transform: rotate(-45deg);
    background: rgb(61, 61, 61);
    z-index: 2;
  }

  &::after {
    content: "SPIN";
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    inset: 0;
    z-index: 3;
    -webkit-tap-highlight-color: transparent;
    font-size: 20px;
    font-weight: bold;
    display: block;
    color: rgb(255, 255, 255);
    user-select: none;
    -webkit-text-stroke: 0.5px rgb(248, 248, 248);
    text-shadow: rgb(0, 0, 0) 0 1px 4px;
    letter-spacing: 0.5px;
  }

  &:hover::after {
    transform: scale(1.2);
  }
}


.btn-grad {
  background-image: linear-gradient(to right, #00d2ff 0%, #928DAB  51%, #00d2ff  100%);
  margin: 0 auto 8px;
  padding: 12px 64px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}



@media (max-width: 500px) {
  .icon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    &::before {
      width: 60px;
      height: 60px;
    }
    &::after {
      width: 60px;
      height: 60px;
      font-size: 12px;
    }
  }

  .icon-back {
    width: 60px;
    height: 60px;
  }
}